import React from 'react'
import { graphql} from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/Layout'
import Link from '../components/Link'
//import { useTheme } from '../components/Theming'
import Container from '../components/Container'

import Img from 'gatsby-image/withIEPolyfill'

//import Hero from '../components/Hero'
//import '../components/style.css'

export default function Index({ data: { site, allMdx } }) {
 //const theme = useTheme()


  return (
    <Layout site={site}>


      <Container 
        css={css`
          padding: 0; 
          margin:0 auto;
          display:flex;
          flex-wrap: wrap;
          height:100%;
       
          @media(max-width:896px){
            flex-direction: column;
              min-width:98%;
              
    
             }
          
        `}
      >
      
        
        {allMdx.edges.map(({ node: post }) => (

          <div
            key={post.id}
            css={css`
            
              overflow:hidden;
              border-top:1px black solid;
              padding-bottom: 0;
              
              max-height:290px;
               
                overflow:hidden;
              width:48%;
             
              margin:7% auto 0px auto;
          
              @media(max-width:896px){
                flex-direction: column;
                width:99%;
               max-height:480px;
              
                overflow:hidden;

               }
             
            `}
          >
            <div css={css`
            
            overflow:hidden;
            
            flex-shrink:0;
            }
            `}>
            
              
            
              <Link role="navigation" 
                to={post.frontmatter.slug}
                aria-label={`View ${post.frontmatter.title}`}
              >
                <h2 css={css`
                  font-size: 20px;
                  letter-spacing:0.06rem;
                  
                 `}>
                {post.frontmatter.title.toUpperCase()}
                </h2>
              </Link>
           
          
          
            { post.frontmatter.banner!==null &&
            <Link role="navigation"
              style={{cursor:'pointer'}}
              to={post.frontmatter.slug}
                 aria-label={`View ${post.frontmatter.title}`}
              >
                
            
                <Img role="banner" 
                css ={css`
                 height:100%;
                 object-fit:cover;
                 object-position:center;
             
                  @media(max-width:646px){
                       min-width:98%;
                        max-height:300px;
                        overflow:hidden;
                      }
                
                `}
                //  css={css`
                //   max-height:220px; 
                //   min-height:220px;
                //   height:100%;
                //   @media(max-width:696px){
                //     min-width:98%;
                //     // max-height:170px; 
                //     // min-height:170px;
                    
                   
                //   }
                //  `}
              fluid={post.frontmatter.banner.childImageSharp.fluid}
                alt={site.siteMetadata.keywords.join(', ')}
              />
          
             </Link>
}
            </div>
            </div>


        ))}
        
        
         
    {/*     <Hero
        titel="Der blaue Orion: Schmetterlinge & Co  "
        text="IN PLANUNG / Datum noch offen"
        info="Wir suchen Liebhaber und Experten, die uns unterstützen."
        termin=""
        background="#3333FF"
        publish="true"
        color="white"
        url="/orion"
      /> */}
{/* <Hero
        titel="Freunde empfehlen"
        text=""
        info="Buch, Musik, Artikel, Film, Ausstellungen, Konzerte, Lesungen "
        termin=""
        background="#4682B4"
        publish="true"
        color="white"
        url="/kulturtipps"
      />    */}
    {/* <Hero
        titel="Blog Regionale Geschichte  "
        text="      
        "
        info=""
        termin=""
        background="skyblue"
        publish="true"
        color="black"
        bild="./images/dieter.jpg"
        url="https://regionalgeschichte-oberes-mittelrheintal.netlify.app"
      />  */}
      
      {/* <Hero
        titel="WICHTIGER HINWEIS"
        text="Retrospektive von Karl-Martin Hartmann & Parcours zu jüdischem Leben im Mittelrheintal verschoben auf das Jahr 2022."
        info="weitere Informationen auf K.O.M."
        termin=""
        background="tomato"
        publish="true"
        color="white"
        url="https://kulturnetz-oberes-mittelrheintal.org/projekte"
      />   */}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 14
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 0)
          id
          fields {
            title
            slug
            date

          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
              fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`
